:root {
  background-color: darkslateblue;
  padding: 0;
  margin: 0;
}

.App {
  height: 100vh;
  padding: 0;
  margin: 0;
  font-size: small;
  text-align: center;
  /* background-color: darkslateblue; */
  /* cornflowerblue */
  /* darkslateblue */
  /* indigo */
  
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.info-cards {
  display: flex;
  flex-direction: row;
}

.info-card {
  width: 30%;
}

.input-container-title {
  margin-top: auto !important;
  margin-bottom: auto !important;
  min-width: 150px;
}

.headerCell > p{
  /* margin: 0; */
}

.data-card-item {
  display: flex;
  width: 100%;
  padding: 8px;
}

.data-card-item:nth-child(even) {
  background-color: aliceblue;
}

.data-card-key{
  width: 100%;
  text-align: left;
}

.data-card-value{
  width: 100%;
  text-align: right;
}

.highlight {
  font-weight: 600;
}